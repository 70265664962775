<template>
  <div class="admin-menu-nav">
    <Collapse
      v-if="$isAdmin || $isCreator"
      icon="blog"
      :title="$locale['posts_label']"
      :class="`${$isView('posts') ? 'onview' : ''}`"
      ref="postsMenu"
      @open="menuOpen('postsMenu')"
      :settings="settings"
    >
      <AdminMenuPosts _view="posts" @filter="menuFilter" :modal="modal" />
    </Collapse>

    <Collapse
      v-if="$isAdmin || $isCreator"
      icon="clock"
      :title="$locale['stories_label']"
      :class="`${$isView('stories') ? 'onview' : ''}`"
      ref="storiesMenu"
      @open="menuOpen('storiesMenu')"
      :settings="settings"
    >
      <AdminMenuStories _view="stories" @filter="menuFilter" :modal="modal" />
    </Collapse>

    <Collapse
      v-if="$isAdmin"
      icon="clipboard_check"
      :title="$locale['verifications_label']"
      :class="`${$isView('verifications') ? 'onview' : ''}`"
      ref="verificationsMenu"
      @open="menuOpen('verificationsMenu')"
      :settings="settings"
    >
      <AdminMenuVerifications @filter="menuFilter" :modal="modal" />
    </Collapse>

    <Collapse
      v-if="$isAdmin"
      icon="pie_chart"
      :title="$locale['statistics_label']"
      :class="`${$isView('statistics') ? 'onview' : ''}`"
      ref="statisticsMenu"
      @open="menuOpen('statisticsMenu')"
      :settings="settings"
    >
      <AdminMenuStatistics _view="statistics" @filter="menuFilter" :modal="modal" />
    </Collapse>

    <Collapse
      v-if="$isCreator"
      icon="stars"
      :title="$locale['creator']"
      :class="`UserCreatorMenu ${$isView('creator') ? 'onview' : ''}`"
      ref="UserCreatorMenu"
      @open="menuOpen('UserCreatorMenu')"
      :settings="{ ...settings, opened: true }"
    >
      <UserCreatorMenu _view="creator" @filter="menuFilter" :modal="modal" />
    </Collapse>

    <Collapse
      icon="user"
      :title="$locale['personal']"
      :class="`UserPersonalMenu ${$isView('user') ? 'onview' : ''}`"
      ref="userMenu"
      @open="menuOpen('userMenu')"
      :settings="settings"
    >
      <AdminMenuOthers _view="user" @filter="menuFilter" :modal="modal" />
    </Collapse>

    <Collapse
      icon="multi_media"
      :title="$locale['my_content']"
      :class="`${$isView('content') ? 'onview' : ''}`"
      ref="contentMenu"
      @open="menuOpen('contentMenu')"
      :settings="settings"
    >
      <UserContentMenu _view="content" class="UserContentMenu" />
    </Collapse>

    <Collapse
      v-if="$isDev"
      icon="code"
      :title="$locale['developer']"
      :class="`UserDeveloperMenu ${$isView('wiki') ? 'onview' : ''}`"
      ref="UserDeveloperMenu"
      @open="menuOpen('UserDeveloperMenu')"
      :settings="settings"
    >
      <DeveloperMenu _view="wiki" @filter="menuFilter" :modal="modal" />
    </Collapse>
  </div>
</template>

<script>
export default {
  props: ["settings", "modal"],
  components: {
    AdminMenuPosts: () => import("./AdminMenuPosts.vue"),
    AdminMenuStories: () => import("./AdminMenuStories.vue"),
    AdminMenuOthers: () => import("./AdminMenuOthers.vue"),
    AdminMenuStatistics: () => import("./AdminMenuStatistics.vue"),
    AdminMenuVerifications: () => import("./AdminMenuVerifications.vue"),
    DeveloperMenu: () => import("../developer/DeveloperMenu.vue"),
    UserCreatorMenu: () => import("../creator/UserCreatorMenu.vue"),
    UserContentMenu: () => import("../user/UserContentMenu.vue"),
  },
  methods: {
    menuFilter: function(filter) {
      this.$emit("filter");
    },
    menuOpen: function(menu) {
      Object.keys(this.$refs).forEach((key) => {
        if (key !== menu) {
          this.$refs[key].close();
        }
      });
    },
  },
};
</script>
